import React, { Component } from 'react'
import { Avatar, Icon, Menu as DSMenu, Stack } from '@toriihq/design-system'
import RelativeTeamLink from '../../relativeTeamLink'
import onClickOutside from 'react-onclickoutside'
import { SearchBarMenuButton, SearchBarArrowIcon } from './style'
import config from '@root/config'

type MenuProps = {
  firstName?: string
  lastName?: string
  photoUrl?: string
  isCatalogEnabledForMembers?: boolean
}

type MenuState = {
  isOpen: boolean
}

class Menu extends Component<MenuProps, MenuState> {
  state: MenuState = {
    isOpen: false
  }

  handleClickOutside = () => {
    this.setState({ isOpen: false })
  }

  onClick = () => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }))
  }

  shouldComponentUpdate (nextProps: MenuProps, nextState: MenuState) {
    const hasUserChanged = (
      this.props.firstName !== nextProps.firstName ||
      this.props.lastName !== nextProps.lastName ||
      this.props.photoUrl !== nextProps.photoUrl
    )
    const isOpenChanged = this.state.isOpen !== nextState.isOpen
    const catalogEnabledChanged = this.props.isCatalogEnabledForMembers !== nextProps.isCatalogEnabledForMembers

    return hasUserChanged || isOpenChanged || catalogEnabledChanged
  }

  render () {
    const { isOpen } = this.state
    const { firstName, lastName, photoUrl, isCatalogEnabledForMembers } = this.props

    const items = [
      {
        type: 'item',
        element: <DSMenu.Item>
          <RelativeTeamLink to='/settings/notifications'>
            Notifications settings
          </RelativeTeamLink>
        </DSMenu.Item>
      },
      isCatalogEnabledForMembers ? {
        type: 'item',
        element: <DSMenu.Item>
          <a href={config.catalogBaseUrl} target='_blank' rel='noopener noreferrer'>
            Application catalog
          </a>
        </DSMenu.Item>
      } : null,
      {
        type: 'item',
        element: <DSMenu.Item>
          <a href='/login/logout'>
            <Stack direction='row' gap='space-050'>
              <Icon name='Logout' color='primary' />
              Log out
            </Stack>
          </a>
        </DSMenu.Item>
      }
    ].filter(Boolean)

    return (
      <DSMenu
        isOpen={isOpen}
        align='end'
        items={items}
      >
        <SearchBarMenuButton isOpen={isOpen} onClick={this.onClick}>
          <Avatar firstName={firstName} lastName={lastName} imageUrl={photoUrl} />
          <SearchBarArrowIcon isOpen={isOpen}>
            <Icon name='ChevronDown' color='inherit' />
          </SearchBarArrowIcon>
        </SearchBarMenuButton>
      </DSMenu>
    )
  }
}

export default onClickOutside(Menu)
